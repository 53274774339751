<template>
  <div class="page">
    <div class="stl_02">
      <div class="stl_view">
        <div class="stl_05 stl_06">
          <div class="stl_01" style="left:0.5em;"><span class="stl_07 stl_08 stl_09">【特别提示条款】 &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;"><span
            class="stl_10 stl_08 stl_09"
          >您的信任对南京瀚星信息科技有限公司（</span><span class="stl_11 stl_08 stl_09">“</span><span
            class="stl_10 stl_08 stl_09"
          >我们</span><span class="stl_11 stl_08 stl_09">”</span><span
            class="stl_10 stl_08 stl_09"
          >）开发运营的兽医首选</span><span class="stl_11 stl_08 stl_09">™</span><span
            class="stl_10 stl_08 stl_09"
          >非常重要，我们深知个 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;"><span
            class="stl_10 stl_08 stl_09"
          >人信息对您的重要性，我们将按照法律法规要求，采取相应安全保护措施，尽力保护您的个人信息。您在 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">使用我们的产品与</span><span
            class="stl_11 stl_08 stl_09"
          >/</span><span
            class="stl_10 stl_08 stl_09"
          >或服务前，请您务必先仔细阅读和理解《兽医首选</span><span
            class="stl_11 stl_08 stl_09"
          >™</span><span class="stl_10 stl_08 stl_09">隐私政策》（</span><span
            class="stl_11 stl_08 stl_09"
          >“</span><span class="stl_10 stl_08 stl_09">本隐私政 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">策</span><span
            class="stl_11 stl_08 stl_09"
          >”</span><span class="stl_10 stl_08 stl_09">），特别应重点阅读我们以</span><span
            class="stl_07 stl_08 stl_09"
          >粗体或粗体下划线</span><span
            class="stl_10 stl_08 stl_09"
          >标识的条款，确保您充分理解和同意后再开始使用。我 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >们希望通过本隐私政策向您说明我们在您使用我们的产品与</span><span
            class="stl_11 stl_08 stl_09"
          >/</span><span class="stl_10 stl_08 stl_09">或服务时如何收集、使用、保存、共享和转
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >让这些信息，以及我们为您提供的访问、更新、删除和保护这些信息的方式。如您阅读过程中有任何疑 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">问，可通过本隐私政策中的联系方式咨询我们。
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">【</span><span
            class="stl_07 stl_08 stl_09"
          >版本更新日期</span><span class="stl_10 stl_08 stl_09">】：</span><span
            class="stl_11 stl_08 stl_12" style="word-spacing:-0.0455em;"
          >2022 </span><span
            class="stl_10 stl_08 stl_09"
          >年【</span><span class="stl_11 stl_08 stl_09">11</span><span
            class="stl_10 stl_08 stl_09"
          >】月【</span><span class="stl_11 stl_08 stl_09">1</span><span
            class="stl_10 stl_08 stl_09"
          >】日 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">【</span><span
            class="stl_07 stl_08 stl_09"
          >版本生效日期</span><span class="stl_10 stl_08 stl_09">】：</span><span
            class="stl_11 stl_08 stl_12" style="word-spacing:-0.0455em;"
          >2022 </span><span
            class="stl_10 stl_08 stl_09"
          >年【</span><span class="stl_11 stl_08 stl_09">11</span><span
            class="stl_10 stl_08 stl_09"
          >】月【</span><span class="stl_11 stl_08 stl_09">7</span><span
            class="stl_10 stl_08 stl_09"
          >】日 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">【</span><span
            class="stl_07 stl_08 stl_09"
          >政策摘要</span><span class="stl_10 stl_08 stl_09">】 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">本政策主要向您说明：
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">【适用范围】 &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >本隐私政策与您所使用的兽医首选</span><span class="stl_11 stl_08 stl_09">™</span><span
            class="stl_10 stl_08 stl_09"
          >网站、客户端、小程序、线下合作店以及随技术发展出现的新 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >形态向您提供的各项产品和服务（以下统称</span><span
            class="stl_11 stl_08 stl_09"
          >“</span><span class="stl_10 stl_08 stl_09">我们的产品与</span><span
            class="stl_11 stl_08 stl_09"
          >/</span><span class="stl_10 stl_08 stl_09">或服务</span><span
            class="stl_11 stl_08 stl_09"
          >”</span><span class="stl_10 stl_08 stl_09">）息息相关，希望您在使用我们的 &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">产品与</span><span
            class="stl_11 stl_08 stl_09"
          >/</span><span
            class="stl_10 stl_08 stl_09"
          >或服务前仔细阅读并确认您已经充分理解本政策所写明的内容，据此您可以按照本隐私政策的指 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >引做出您认为适当的选择。本隐私政策适用于我们的产品与</span><span
            class="stl_11 stl_08 stl_09"
          >/</span><span class="stl_10 stl_08 stl_09">或服务的最新版本。如我们关联公司的产品
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >或服务中使用了兽医首选</span><span class="stl_11 stl_08 stl_09">™</span><span
            class="stl_10 stl_08 stl_09"
          >提供的产品或服务但未设置独立隐私政策的，则本隐私政策同样适用于该部分 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">产品或服务。 &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">【</span><span
            class="stl_10 stl_08 stl_09"
          >我们如何收集和使用您的个人信息</span><span class="stl_07 stl_08 stl_09">】 &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">兽医首选</span><span
            class="stl_11 stl_08 stl_09"
          >™</span><span
            class="stl_10 stl_08 stl_09"
          >为您提供便捷的电子商务交易平台服务，为了实现服务的提供，我们主要会基于如下需 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">要收集、使用、共享、存储您的个人信息：
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">用户注册</span><span
            class="stl_10 stl_08 stl_09"
          >：您在访问我们的产品及服务时，可匿名浏览有限页面信息，但在您下单交易或享受其他 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >服务时，需要您注册并审核成为兽医首选</span><span class="stl_11 stl_08 stl_09">™</span><span
            class="stl_10 stl_08 stl_09"
          >用户。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >展示和推送商品和服务</span><span
            class="stl_10 stl_08 stl_09"
          >：为帮助您快速找到您所需要的商品和服务，或在您搜索时向您展示您可能 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >希望找到的商品，我们会通过技术方式结合您的行为偏好特征，向您推荐您可能需要的商品</span><span
            class="stl_11 stl_08 stl_09"
          >/</span><span class="stl_10 stl_08 stl_09">服务。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">展示和推送广告：</span><span
            class="stl_10 stl_08 stl_09"
          >我们会通过技术方式结合您的行为偏好特征，向您提供更契合您需求的广告信 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">息。 &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">下单及交易履约</span><span
            class="stl_10 stl_08 stl_09"
          >：您需要提供收货人、企业名称、收货地址、联系电话信息以便于我们帮助您顺利 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">完成交易并保障您的交易安全。
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">客服及争议处理</span><span
            class="stl_10 stl_08 stl_09"
          >：在为您提供客服或争议纠纷处理服务时，我们需要收集和处理您的必要信息，用 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >于验证您的身份、向您回复及回访我们服务人员的服务质量。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">安全保障</span><span
            class="stl_10 stl_08 stl_09"
          >：为保障您的账户及交易安全，我们可能需要收集和处理您的设备信息、日志信息，我们 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >也会将这些信息用于预防发生各类违法违规或其他主体合法权益被侵害的情况。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">其他附加功能</span><span
            class="stl_10 stl_08 stl_09"
          >：为便于您实现购物、评价、分享、存储图片以及与客服沟通等服务需求，我们还为 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >您提供了很多附加功能。例如您申请售后时为了更好地阐述需要服务的内容，会申请您的相册权限上传您 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">选择的图片，对此您可按需选择使用或关闭。
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >对于上述您的个人信息，大部分是由您主动向我们提供的，也有我们在您使用我们的产品与</span><span
            class="stl_11 stl_08 stl_09"
          >/</span><span class="stl_10 stl_08 stl_09">或服务 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">时通过 </span><span
            class="stl_11 stl_08 stl_09"
          >Cookies</span><span class="stl_10 stl_08 stl_09">、</span><span
            class="stl_11 stl_08 stl_13" style="word-spacing:-0.0461em;"
          >SDK </span><span
            class="stl_10 stl_08 stl_09"
          >及类似技术获取的，还有部分是从第三方间接获取的。 &nbsp;</span></div>
        </div>
      </div>
    </div>
    <div class="stl_02">
      <div class="stl_03">
        <object data="兽医首选隐私政策_files/img_02.svg" type="image/svg+xml" class="stl_04"
                style="position:absolute; width:49.5833em; height:70.0833em;"
        >
          <embed src="兽医首选隐私政策_files/img_02.svg" type="image/svg+xml">
        </object>
      </div>
      <div class="stl_view">
        <div class="stl_05 stl_06">
          <div class="stl_01" style="left:0.5em;"><span class="stl_10 stl_08 stl_09">【我们如何共享、转让、公开披露您的个人信息】
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;"><span
            class="stl_10 stl_08 stl_09"
          >请您理解，我们无法单独向您提供上述全部的服务，为满足向您提供上述服务的必要，我们需要向 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;"><span
            class="stl_10 stl_08 stl_09"
          >我们的关联公司、供应商、合作伙伴及其他第三方以共享或委托处理的方式提供您的部分个人信息，但仅 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >限于与处理目的相关的必要范围。我们会采取必要的技术和管理措施尽可能地保护您的个人信息安全。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >另外，我们也会根据您的选择，向与您交易的第三方商家或其他交易对象提供您的个人信息，以便 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">其能够提供您所选择的商品或服务。
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">【您如何管理自己的信息】
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >您可以通过多种方式管理您的个人信息，例如直接在个人中心查阅、更正、补充、删除您的个人信 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >息，以及注销您的账户。如果您在管理您的个人信息时遇到问题，您还可以通过下方联系方式与我们联 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">系，我们将在 </span><span
            class="stl_11 stl_08 stl_14" style="word-spacing:-0.0446em;"
          >15 </span><span
            class="stl_10 stl_08 stl_09"
          >天内回复您的要求。但也请您理解，我们可能会因为履行法律法规义务或者我们提供服 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">务必要性的原因无法完全满足您的请求。
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">【联系我们】 &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;z-index:439;"><span
            class="stl_10 stl_08 stl_09"
          >如您对本政策有任何疑问</span><span class="stl_10 stl_08 stl_15">、</span><span
            class="stl_10 stl_08 stl_09"
          >意</span><span class="stl_10 stl_08 stl_09">见或建议</span><span
            class="stl_10 stl_08 stl_15"
          >，</span><span class="stl_10 stl_08 stl_09">或</span><span
            class="stl_10 stl_08 stl_09"
          >者您在管理您的个人信息时遇到任何问题</span><span class="stl_10 stl_08 stl_15">，</span><span
            class="stl_10 stl_08 stl_09"
          >您</span><span class="stl_10 stl_08 stl_09">可联系兽医首选</span><span
            class="stl_11 stl_08 stl_09"
          >™ &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_16"
          >客户服务部进行初步咨询，客户服务部电话：</span><span
            class="stl_11 stl_08 stl_17"
          >025-86210186</span><span class="stl_10 stl_08 stl_16">。如您需要获取具体个人信息副本，您可以
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >通过发送邮件至我们专门的个人信息保护邮箱：</span><a href="mailto:office@petcare.cn"
                                          target="_blank"
          ><span class="stl_11 stl_08 stl_09" style="word-spacing:-0.0454em;">office@petcare.cn
          </span></a><span class="stl_10 stl_08 stl_09">的方式与我们联系。 &nbsp;</span></div>
          <div class="stl_01" style="left:21.7418em;;"><span class="stl_07 stl_08 stl_09">《兽医首选</span><span
            class="stl_18 stl_08 stl_09"
          >™</span><span class="stl_07 stl_08 stl_09">隐私政策》 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">【定义及适用范围】
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >一、本隐私政策与您所使用的</span><span class="stl_07 stl_08 stl_09">兽医首选</span><span
            class="stl_18 stl_08 stl_09"
          >™</span><span class="stl_07 stl_08 stl_09">网站、客户端、小程序、线下合作店以及随技术发展出现
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >的新形态向您提供的各项产品和服务（以下统称</span><span
            class="stl_18 stl_08 stl_09"
          >“</span><span class="stl_07 stl_08 stl_09">我们的产品与</span><span
            class="stl_18 stl_08 stl_09"
          >/</span><span class="stl_07 stl_08 stl_09">或服务</span><span
            class="stl_18 stl_08 stl_09"
          >”</span><span class="stl_07 stl_08 stl_09">）</span><span
            class="stl_10 stl_08 stl_09"
          >息息相关，希望您在使用我 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">们的产品与</span><span
            class="stl_11 stl_08 stl_09"
          >/</span><span
            class="stl_10 stl_08 stl_09"
          >或服务前仔细阅读并确认您已经充分理解本政策所写明的内容，据此您可以按照本隐私政策 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >的指引做出您认为适当的选择。本隐私政策适用于我们的产品与</span><span
            class="stl_11 stl_08 stl_09"
          >/</span><span class="stl_10 stl_08 stl_09">或服务的最新版本。</span><span
            class="stl_07 stl_08 stl_09"
          >如我们关联公司的 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >产品或服务中使用了兽医首选</span><span class="stl_18 stl_08 stl_09">™</span><span
            class="stl_07 stl_08 stl_09"
          >提供的产品或服务但未设置独立隐私政策的，则本隐私政策同样适用于该 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">部分产品或服务</span><span
            class="stl_10 stl_08 stl_09"
          >。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >二、本隐私政策所称的关联公司是指：一方直接或间接控制、共同控制另一方，以及两方或两方以 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >上受同一主体控制、共同控制的公司。前述</span><span
            class="stl_11 stl_08 stl_09"
          >“</span><span class="stl_10 stl_08 stl_09">控制</span><span
            class="stl_11 stl_08 stl_09"
          >”</span><span class="stl_10 stl_08 stl_09">是指，直接或间接拥有的权力，从而通过行使表决
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >权、合同或其他方式决定或影响某一方的管理或决策方向。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >三、本隐私政策中涉及的相关术语，我们尽量以简明扼要的表述，并提供进一步说明的链接，以便 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >您更好地理解。您使用或在我们更新本隐私政策后（我们会及时提示您更新的情况）继续使用我们的产品 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">与</span><span
            class="stl_11 stl_08 stl_09"
          >/</span><span class="stl_10 stl_08 stl_09">或服务，即意味着您同意本隐私政策</span><span
            class="stl_11 stl_08 stl_09"
          >(</span><span class="stl_10 stl_08 stl_09">含更新版本</span><span
            class="stl_11 stl_08 stl_09"
          >)</span><span class="stl_10 stl_08 stl_09">内容，并且同意我们按照本隐私政策收集、使用、保
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">存和共享您的相关信息。
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">本隐私政策将帮助您了解以下内容：
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">一、我们如何收集和使用您的个人信息
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09"
                                                         style="word-spacing:0.25em;"
          >二、我们如何使用 </span><span class="stl_11 stl_08 stl_09"
                                 style="word-spacing:0.204em;"
          >Cookie </span><span class="stl_10 stl_08 stl_09">和同类技术 &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >三、我们如何共享、转让、公开披露您的个人信息 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">四、我们如何保护和保存您的个人信息
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">五、您如何管理您的个人信息
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">六、您的个人信息如何在全球范围转移
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">七、通知和修订 &nbsp;</span>
          </div>
        </div>
      </div>
    </div>
    <div class="stl_02">
      <div class="stl_03">
        <object data="兽医首选隐私政策_files/img_03.svg" type="image/svg+xml" class="stl_04"
                style="position:absolute; width:49.5833em; height:70.0833em;"
        >
          <embed src="兽医首选隐私政策_files/img_03.svg" type="image/svg+xml">
        </object>
      </div>
      <div class="stl_view">
        <div class="stl_05 stl_06">
          <div class="stl_01" style="left:0.5em;"><span class="stl_10 stl_08 stl_09">八、如何联系我们 &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;"><span class="stl_07 stl_08 stl_09">一、我们如何收集和使用您的个人信息
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >个人信息是以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >化处理后的信息。敏感个人信息是一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >迹、企业名称、营业执照、企业组织机构代码证的认证登记等信息。</span><span
            class="stl_07 stl_08 stl_09"
          >本政策中对涉及到的敏感个人信息以 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">粗体或加下划线方式进行显著标识。
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >我们将遵循合法、正当、必要和诚信原则，在本政策范围内收集和使用您的个人信息： &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">（一）您授权我们收集和使用您个人信息的情形
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">我们的产品与</span><span
            class="stl_11 stl_08 stl_09"
          >/</span><span
            class="stl_10 stl_08 stl_09"
          >或服务包括一些核心功能，这些功能包含了实现网上购物所必须的功能、改进我们的 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">产品与</span><span
            class="stl_11 stl_08 stl_09"
          >/</span><span
            class="stl_10 stl_08 stl_09"
          >或服务所必须的功能及保障交易安全所必须的功能。</span><span
            class="stl_07 stl_08 stl_09"
          >我们可能会收集、保存和使用下列与您有关 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >的信息才能实现上述这些功能。如果您不提供相关信息，您将无法享受我们提供的产品与</span><span
            class="stl_18 stl_08 stl_09"
          >/</span><span class="stl_07 stl_08 stl_09">或服务。这些 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">功能包括： &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_18 stl_08 stl_09">1</span><span
            class="stl_07 stl_08 stl_09"
          >、实现网上购物和交易安全所必需的功能 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">（</span><span
            class="stl_18 stl_08 stl_09"
          >1</span><span class="stl_07 stl_08 stl_09">）帮助您成为我们的用户 &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >为成为我们的用户，以便我们为您提供用户服务，您需要提供</span><span
            class="stl_07 stl_08 stl_09"
          >手机号码</span><span class="stl_10 stl_08 stl_09">，并创建用户名和密码。您 &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >使用第三方账号注册成为我们的用户或者登录用户的，仍需要您提供手机号码，用于创建兽医首选</span><span
            class="stl_11 stl_08 stl_09"
          >™</span><span class="stl_10 stl_08 stl_09">账户 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >或绑定您已有的兽医首选</span><span class="stl_11 stl_08 stl_09">™</span><span
            class="stl_10 stl_08 stl_09"
          >账户。我们将通过发送短信验证码的方式来验证您的身份是否有效，通过 </span><span
            class="stl_11 stl_08 stl_09"
          >SIM &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >卡信息识别并显示对应的运营商。如果您仅需使用有限的基本服务，您不需要注册成为我们的用户及提供 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">上述信息。 &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >在注册用户过程中，如果您提供以下额外信息补全资料，，您需要提供以下额外资料补全账号信 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >息，以便我们给您提供相关的产品或服务：您的企业名称、营业执照、企业组织机构代码证、行业类型、 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >您的职位、企业认证邮箱。如果您不提供这些信息，将会影响到您使用完全的浏览、搜索、购买等用户服 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">务。 &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">（</span><span
            class="stl_18 stl_08 stl_09"
          >2</span><span class="stl_07 stl_08 stl_09">）为您展示和推送商品和服务 &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >为了向您提供适配的页面展示，帮助您快速找到您所需要的商品和服务，以及给您提供便捷的配送 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >服务，我们会收集关于您使用的我们的产品与</span><span
            class="stl_18 stl_08 stl_09"
          >/</span><span
            class="stl_07 stl_08 stl_09"
          >或服务以及使用方式的信息并将这些信息进行关联。</span><span class="stl_10 stl_08 stl_09">这些
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">信息包括： &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >设备信息：我们会根据您在软件安装及使用中主动授予的具体权限，接收并记录您所使用的设备相 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">关信息，包括设备名称、设备型号、设备
          </span><span class="stl_11 stl_08 stl_19" style="word-spacing:-0.0466em;">MAC </span><span
            class="stl_10 stl_08 stl_09"
          >地址、操作系统和应用程序版本、语言设置、分辨率、移 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >动应用列表（软件安装列表）、唯一设备识别码（</span><span class="stl_11 stl_08 stl_09"
                                              style="word-spacing:-0.0002em;"
          >IDFA/OPENUDID/GUID/OAID/ ANDROIDID /UUID &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >信息，以您所使用的设备及系统所支持的识别码为准）、运营商网络类型，设备所在位置相关信息（包括 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">经授权的 </span><span
            class="stl_18 stl_08 stl_20" style="word-spacing:-0.0472em;"
          >GPS </span><span
            class="stl_07 stl_08 stl_09"
          >位置</span><span class="stl_10 stl_08 stl_09">以及能够提供相关信息的 </span><span
            class="stl_11 stl_08 stl_12" style="word-spacing:-0.0453em;"
          >WLAN </span><span
            class="stl_10 stl_08 stl_09"
          >接入点、蓝牙和基站传感器信息）。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >日志信息：当您使用我们的产品或服务时，我们会自动收集您对我们服务的详细使用情况，作为有 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >关网络日志保存，包括您的搜索记录、浏览器类型、浏览记录、订单信息、关注收藏及分享信息、</span><span
            class="stl_11 stl_08 stl_13" style="word-spacing:-0.046em;"
          >IP </span><span
            class="stl_10 stl_08 stl_13" style="word-spacing:-0.046em;"
          >地 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">址、访问日期和时间及您访问的网页记录。
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >请注意，单独的设备信息、日志信息是无法识别特定自然人身份的信息。</span><span
            class="stl_10 stl_08 stl_09"
          >如果我们将这类非个人信 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非 &nbsp;</span></div>
        </div>
      </div>
    </div>
    <div class="stl_02">
      <div class="stl_03">
        <object data="兽医首选隐私政策_files/img_04.svg" type="image/svg+xml" class="stl_04"
                style="position:absolute; width:49.5833em; height:70.0833em;"
        >
          <embed src="兽医首选隐私政策_files/img_04.svg" type="image/svg+xml">
        </object>
      </div>
      <div class="stl_view">
        <div class="stl_05 stl_06">
          <div class="stl_01" style="left:0.5em;"><span
            class="stl_10 stl_08 stl_09"
          >个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;"><span class="stl_10 stl_08 stl_09">标识化处理。 &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;"><span
            class="stl_07 stl_08 stl_09"
          >为了将您感兴趣的商品或服务信息展示给您，或在您搜索时向您展示您可能希望找到的商品，我们 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >将根据您的上述设备信息、日志信息等提取您的偏好特征，进行数据分析以形成用户画像。</span><span
            class="stl_10 stl_08 stl_09"
          >我们还可能为 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >了提供服务及改进服务质量的合理需要而获得的您的其他信息，包括您与客服联系时您提供的相关信息， &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >您参与问卷调查时向我们发送的问卷答复信息，以及您与我们的关联方、我们合作伙伴之间互动时我们获 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >得的相关信息。对于从您的各种设备上收集到的信息，我们可能会将它们进行关联，以便我们能在这些设 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >备上为您提供一致的服务。我们可能会将来自某项服务的信息与来自其他服务的信息结合起来，以便为您 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >提供服务、个性化内容和建议，并向您推送您可能感兴趣的商业广告及其他信息。上述做法可能会对您的 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >商品浏览页面展示、商品搜索页面排序、商品推荐页面展示产生影响。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >如果您不希望受个性化内容以及商业广告的影响，您可以通过如下方式保障您的体验： &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">---</span><span
            class="stl_10 stl_08 stl_09"
          >如果您不想接受我们给您发送的商业广告，您可随时根据短信提示或我们提供的其他方式进行退 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">订； &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">---</span><span
            class="stl_10 stl_08 stl_09"
          >您可以在搜索框下方删除或管理您此前输入的搜索关键字信息； &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">---</span><span
            class="stl_10 stl_08 stl_09"
          >您可以在购物车、个人中心的</span><span class="stl_11 stl_08 stl_09">“</span><span
            class="stl_10 stl_08 stl_09"
          >商品收藏</span><span class="stl_11 stl_08 stl_09">”</span><span
            class="stl_10 stl_08 stl_09"
          >管理或删除您的加购、收藏信息； &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">---</span><span
            class="stl_10 stl_08 stl_09"
          >您可以在商品展示页选择按照分类进行浏览和查找商品和服务，我们还在搜索结果中向您提供了 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">按照价格、销量排序等不针对个人特征的选项；
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">（</span><span
            class="stl_18 stl_08 stl_09"
          >3</span><span class="stl_07 stl_08 stl_09">）下单 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">当您准备对您购物车</span><span
            class="stl_11 stl_08 stl_09"
          >/</span><span class="stl_10 stl_08 stl_09">购物袋内的商品进行结算时，兽医首选</span><span
            class="stl_11 stl_08 stl_09"
          >™</span><span class="stl_10 stl_08 stl_09">系统会生成您购买该商品的订单。</span><span
            class="stl_07 stl_08 stl_09"
          >您 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >需要在订单中至少填写您的收货人姓名、企业名称、收货地址以及手机号码，同时该订单中会载明订单 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >号、您所购买的商品或服务信息、您应支付的货款金额及支付方式；您可以另外填写收货人的固定电话、 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >邮箱地址信息以增加更多的联系方式确保商品可以准确送达，但不填写这些信息不影响您订单的生成。若 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >您需要开具发票，还需要提供发票抬头、纳税人识别号以及接收发票电子邮箱。对于部分特殊类型的商品 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >和服务您还需要提供该商品或服务所必需的其他信息，涉及跨境电子商务零售商品交易时您需要提供您的 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">身份证号以完成清关。
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >上述所有信息构成您的</span><span class="stl_11 stl_08 stl_09">“</span><span
            class="stl_10 stl_08 stl_09"
          >订单信息</span><span class="stl_11 stl_08 stl_09">”</span><span
            class="stl_10 stl_08 stl_09"
          >，我们将使用您的订单信息来进行您的身份核验、确定交易、 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >支付结算、完成配送、为您查询订单以及提供客服咨询与售后服务；我们还会使用您的订单信息来判断您 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">的交易是否存在异常以保护您的交易安全。
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">为了确保您的</span><span
            class="stl_11 stl_08 stl_09"
          >“</span><span class="stl_10 stl_08 stl_09">订单信息</span><span
            class="stl_11 stl_08 stl_09"
          >”</span><span
            class="stl_10 stl_08 stl_09"
          >能实现上述服务与功能，我们需要您提供的</span><span
            class="stl_11 stl_08 stl_09"
          >“</span><span class="stl_10 stl_08 stl_09">订单信息</span><span
            class="stl_11 stl_08 stl_09"
          >”</span><span class="stl_10 stl_08 stl_09">是真实且准确 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >的，如果您在订单生成后更改了</span><span class="stl_11 stl_08 stl_09">“</span><span
            class="stl_10 stl_08 stl_09"
          >订单信息</span><span class="stl_11 stl_08 stl_09">”</span><span
            class="stl_10 stl_08 stl_09"
          >，包括入线物流承运商更改等，您同意第三方将您修改后的 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">“</span><span
            class="stl_10 stl_08 stl_09"
          >订单信息</span><span class="stl_11 stl_08 stl_09">”</span><span
            class="stl_10 stl_08 stl_09"
          >完整的共享给我们。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">（</span><span
            class="stl_18 stl_08 stl_09"
          >4</span><span class="stl_07 stl_08 stl_09">）支付功能 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >在您下单后，您可以选择与兽医首选</span><span class="stl_11 stl_08 stl_09">™</span><span
            class="stl_10 stl_08 stl_09"
          >的关联方（兽医首选</span><span class="stl_11 stl_08 stl_09">™</span><span
            class="stl_10 stl_08 stl_09"
          >账期分期服务）或合作的第三方支付 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09"
                                                         style="word-spacing:0.25em;"
          >机构（包括支付宝、微信支付及云闪付， 以下统称</span><span
            class="stl_11 stl_08 stl_09"
          >“</span><span class="stl_10 stl_08 stl_09">支付机构</span><span
            class="stl_11 stl_08 stl_09"
          >”</span><span class="stl_10 stl_08 stl_09">）所提供的支付服务。我们需要将您的
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >订单号与交易金额信息、订单安全相关设备信息</span><span
            class="stl_10 stl_08 stl_09"
          >与这些支付机构共享以实现其确认您的支付指令并完成支 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >付。在您使用支付功能时，支付机构会通过其页面收集或验证您的</span><span
            class="stl_07 stl_08 stl_09"
          >银行卡（含储蓄卡、信用卡）及其他支 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">付必要信息</span><span
            class="stl_10 stl_08 stl_09"
          >，以实现其确认您的支付指令并完成支付，具体适用各支付机构相应的隐私政策及相关用户服 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >务协议。若您使用兽医首选</span><span class="stl_11 stl_08 stl_09">™</span><span
            class="stl_10 stl_08 stl_09"
          >账期分期服务，由兽医首选</span><span class="stl_11 stl_08 stl_09">™</span><span
            class="stl_10 stl_08 stl_09"
          >的关联方南京瀚星信息科技有限公司提供服务 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >和技术支持，南京瀚星信息科技有限公司会将您的包括</span><span
            class="stl_07 stl_08 stl_09"
          >银行卡号、有效期在内的银行卡（包括储蓄卡、信 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >用卡）支付、企业信息等必要信息</span><span
            class="stl_10 stl_08 stl_09"
          >委托给您选择的相应金融机构处理以完成支付。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">（</span><span
            class="stl_18 stl_08 stl_09"
          >5</span><span class="stl_07 stl_08 stl_09">）交付产品或服务功能 &nbsp;</span></div>
        </div>
      </div>
    </div>
    <div class="stl_02">
      <div class="stl_03">
        <object data="兽医首选隐私政策_files/img_05.svg" type="image/svg+xml" class="stl_04"
                style="position:absolute; width:49.5833em; height:70.0833em;"
        >
          <embed src="兽医首选隐私政策_files/img_05.svg" type="image/svg+xml">
        </object>
      </div>
      <div class="stl_view">
        <div class="stl_05 stl_06">
          <div class="stl_01" style="left:0.5em;"><span
            class="stl_10 stl_08 stl_09"
          >为便于向您交付商品或服务，您需提供</span><span
            class="stl_07 stl_08 stl_09"
          >真实的收货人姓名、收货地址、收货人联系电话</span><span class="stl_10 stl_08 stl_09">。如果我们
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;"><span
            class="stl_10 stl_08 stl_09"
          >委托第三方向您交付时，您同意将上述信息共享给第三方。如果您在订单生成后修改了上述信息，您同意 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;"><span class="stl_10 stl_08 stl_09">兽医首选</span><span
            class="stl_11 stl_08 stl_09"
          >™</span><span
            class="stl_10 stl_08 stl_09"
          >和第三方均能共享上述信息。如果您拒绝提供或者不同意共享此类信息，我们将无法完成相关 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">交付服务。 &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">您可以通过兽医首选</span><span
            class="stl_18 stl_08 stl_09"
          >™</span><span
            class="stl_07 stl_08 stl_09"
          >为其他人订购商品或服务，您需要提供该实际订购人的前述个人信息。向兽 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">医首选</span><span
            class="stl_18 stl_08 stl_09"
          >™</span><span
            class="stl_07 stl_08 stl_09"
          >提供该实际订购人的前述个人信息之前，您需确保您已经取得其授权同意。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">（</span><span
            class="stl_18 stl_08 stl_09"
          >6</span><span class="stl_07 stl_08 stl_09">）客服与售后功能 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >我们的电话客服和售后功能会使用您的账号信息和订单信息。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >为保证您的账号安全，我们的呼叫中心客服和在线客服会使用您的账号信息与您核验您的身份。当 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >您需要我们提供与您订单信息相关的客服与售后服务时，我们将会查询您的订单信息。您有可能会在与我 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >们的客服人员沟通时，提供给出上述信息外的其他信息，如当您要求我们变更配送地址、联系人或联系电 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">话。</span><span
            class="stl_07 stl_08 stl_09"
          >您也可以通过我们提供自助方式进行操作退换货申请、上门揽退等服务，届时可能需要您提供寄件人 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >信息（包括寄件人姓名、联系方式、地址）</span><span class="stl_10 stl_08 stl_09">。
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_18 stl_08 stl_09">2</span><span
            class="stl_07 stl_08 stl_09"
          >、为您提供安全保障 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >为提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，保护您或其他用户或公众的人身 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >险，更准确地识别违反法律法规或兽医首选</span><span
            class="stl_11 stl_08 stl_09"
          >™</span><span class="stl_10 stl_08 stl_09">相关协议规则的情况，</span><span
            class="stl_07 stl_08 stl_09"
          >我们可能使用或整合您的用户信息、 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">交易信息、设备信息（设备型号、设备
          </span><span class="stl_18 stl_08 stl_09" style="word-spacing:-0.0479em;">MAC </span><span
            class="stl_07 stl_08 stl_09"
          >地址、应用进程列表（运行中的进程信息）、设备识别码、 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >操作系统及软件版本、设备状态、网络状况）、日志信息、</span><span class="stl_18 stl_08 stl_14"
                                                  style="word-spacing:-0.0465em;"
          >IP </span><span class="stl_07 stl_08 stl_09">地址、位置信息、浏览信息、订单信息、
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">常用软件列表、</span><span
            class="stl_18 stl_08 stl_09"
          >BSSID</span><span class="stl_07 stl_08 stl_09">、</span><span
            class="stl_18 stl_08 stl_09"
          >SSID</span><span class="stl_07 stl_08 stl_09">、</span><span
            class="stl_18 stl_08 stl_13" style="word-spacing:-0.0483em;"
          >Wi-Fi </span><span
            class="stl_07 stl_08 stl_09"
          >信息、电池使用情况、有关网络日志以及我们关联公司、合作伙 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >伴取得您授权或依据法律共享的信息，来综合判断您账户及交易风险、进行身份验证、检测及防范安全事 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >件，并依法采取必要的记录、审计、分析、处置措施。我们还可能在应用程序中嵌入我们合作的第三方合 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >作伙伴开发的应用安全类的软件工具开发包（在本隐私政策中简称</span><span
            class="stl_18 stl_08 stl_09"
          >“SDK”</span><span class="stl_07 stl_08 stl_09">）收集您的设备信息、服务日
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >志信息，以验证您的账户安全，保护您的财产权益免遭侵害。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >（二）您可选择是否授权我们收集和使用您的个人信息的情形 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">1</span><span
            class="stl_10 stl_08 stl_09"
          >、为提升您的购物体验和保障我们的产品</span><span class="stl_11 stl_08 stl_09">/</span><span
            class="stl_10 stl_08 stl_09"
          >服务功能实现与安全稳定运行目的，我们可能会根据您 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">选择的具体附加功能</span><span
            class="stl_11 stl_08 stl_09"
          >/</span><span class="stl_10 stl_08 stl_09">服务申请或使用操作系统的相关权限。</span><span
            class="stl_07 stl_08 stl_09"
          >如果您不同意授权 </span><span class="stl_18 stl_08 stl_12"
                                 style="word-spacing:-0.0475em;"
          >APP </span><span class="stl_07 stl_08 stl_09">获取相关操作系统权 &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >限，不会影响您使用我们提供的基本业务功能（基本业务功能所依赖的必要操作系统权限除外），但您可 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >能无法获得附加服务给您带来的用户体验。</span><span
            class="stl_10 stl_08 stl_09"
          >您可在您的设备设置或</span><span class="stl_11 stl_08 stl_09">“</span><span
            class="stl_10 stl_08 stl_09"
          >个人中心</span><span class="stl_11 stl_08 stl_13">-</span><span
            class="stl_10 stl_08 stl_09"
          >设置</span><span class="stl_11 stl_08 stl_09">-</span><span
            class="stl_10 stl_08 stl_09"
          >隐私</span><span class="stl_11 stl_08 stl_13">-</span><span
            class="stl_10 stl_08 stl_09"
          >系统权限设 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">置</span><span
            class="stl_11 stl_08 stl_09"
          >”</span><span class="stl_10 stl_08 stl_09">中逐项查看 </span><span
            class="stl_11 stl_08 stl_20" style="word-spacing:-0.0451em;"
          >APP </span><span
            class="stl_10 stl_08 stl_09"
          >权限的状态，并可自行随时决定这些权限的开启或关闭。此外，我们还会基于剪贴 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >板为您提供部分附加功能，当您填写地址信息时，您的剪贴板功能可能会被调用。剪贴板信息仅会在您的 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >设备上进行处理，我们不会存储您的剪贴板信息用于任何其他途径。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">2</span><span
            class="stl_10 stl_08 stl_09"
          >、上述附加功能我们会通过弹窗方式提示告知您我们所需要获取的权限。如您拒绝提供对应权 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">限，在随后的 </span><span
            class="stl_11 stl_08 stl_14" style="word-spacing:-0.0446em;"
          >48 </span><span
            class="stl_10 stl_08 stl_09"
          >小时内您重新打开 </span><span class="stl_11 stl_08 stl_20"
                                 style="word-spacing:-0.0451em;"
          >APP </span><span
            class="stl_10 stl_08 stl_09"
          >时，我们将不会主动地重复向您申请该权限以避免对您产生过于 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >频繁的打扰（除非您主动需要使用特定功能）。如您是安卓用户，您可以通过移动设备</span><span
            class="stl_11 stl_08 stl_13"
          >-</span><span class="stl_10 stl_08 stl_09">设置</span><span
            class="stl_11 stl_08 stl_09"
          >-</span><span class="stl_10 stl_08 stl_09">权限管理逐 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">项查看您 </span><span
            class="stl_11 stl_08 stl_20" style="word-spacing:-0.0451em;"
          >APP </span><span
            class="stl_10 stl_08 stl_09"
          >权限的开启状态，并可以决定将这些权限随时的开启或关闭。</span><span
            class="stl_07 stl_08 stl_09"
          >请您注意，您开启这些权限 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >即代表您授权我们可以收集和使用这些个人信息来实现上述的功能，您关闭权限即代表您取消了这些授 &nbsp;</span></div>
        </div>
      </div>
    </div>
    <div class="stl_02">
      <div class="stl_03">
        <object data="兽医首选隐私政策_files/img_06.svg" type="image/svg+xml" class="stl_04"
                style="position:absolute; width:49.5833em; height:70.0833em;"
        >
          <embed src="兽医首选隐私政策_files/img_06.svg" type="image/svg+xml">
        </object>
      </div>
      <div class="stl_view">
        <div class="stl_05 stl_06">
          <div class="stl_01" style="left:0.5em;"><span
            class="stl_07 stl_08 stl_09"
          >权，则我们将不再继续收集和使用您的这些个人信息，也无法为您提供与这些授权所对应的功能。您关闭 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;"><span
            class="stl_07 stl_08 stl_09"
          >权限的决定不会影响此前基于您的授权所进行的个人信息的处理</span><span class="stl_10 stl_08 stl_09">。
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >（三）以下情形，我们收集、使用您的个人信息，无需征得您授权同意： &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">1</span><span
            class="stl_10 stl_08 stl_09"
          >、与国家安全、国防安全有关的； &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">2</span><span
            class="stl_10 stl_08 stl_09"
          >、与公共安全、公共卫生、重大公共利益有关的； &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">3</span><span
            class="stl_10 stl_08 stl_09"
          >、与刑事侦查、起诉、审判和判决执行等有关的； &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">4</span><span
            class="stl_10 stl_08 stl_09"
          >、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的； &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">5</span><span
            class="stl_10 stl_08 stl_09"
          >、所收集的个人信息是个人信息主体自行向社会公众公开的； &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">6</span><span
            class="stl_10 stl_08 stl_09"
          >、从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道； &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">7</span><span
            class="stl_10 stl_08 stl_09"
          >、根据您的要求签订合同所必需的； &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">8</span><span
            class="stl_10 stl_08 stl_09"
          >、用于维护所提供的产品与</span><span class="stl_11 stl_08 stl_09">/</span><span
            class="stl_10 stl_08 stl_09"
          >或服务的安全稳定运行所必需的，例如发现、处置产品与</span><span
            class="stl_11 stl_08 stl_09"
          >/</span><span class="stl_10 stl_08 stl_09">或服务的故 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">障； &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">9</span><span
            class="stl_10 stl_08 stl_09"
          >、为合法的新闻报道所必需的； &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">10</span><span
            class="stl_10 stl_08 stl_09"
          >、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >时，对结果中所包含的个人信息进行去标识化处理的； &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">11</span><span
            class="stl_10 stl_08 stl_09"
          >、法律法规规定的其他情形。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">（四）我们从第三方获得您个人信息的情形
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >当您通过第三方账户直接登录并使用我们的产品与</span><span
            class="stl_11 stl_08 stl_09"
          >/</span><span class="stl_10 stl_08 stl_09">或服务时，我们可能从第三方获取您授权共享的
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">账户信息（</span><span
            class="stl_07 stl_08 stl_09"
          >头像、昵称、手机号、邮箱号及授权页面提示的信息</span><span
            class="stl_10 stl_08 stl_09"
          >），并在您同意本隐私政策后将您的第三 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >方账户与您的兽医首选</span><span class="stl_11 stl_08 stl_09">™</span><span
            class="stl_10 stl_08 stl_09"
          >账户绑定。我们会将依据与第三方的约定、对个人信息来源的合法性进行确认 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >后，在符合相关法律和法规规定的前提下，使用您的这些个人信息。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">（五）您个人信息使用的规则
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">1</span><span
            class="stl_10 stl_08 stl_09"
          >、我们会根据本隐私政策的约定并为实现我们的产品与</span><span
            class="stl_11 stl_08 stl_09"
          >/</span><span class="stl_10 stl_08 stl_09">或服务功能对所收集的个人信息进行使
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">用。 &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">2</span><span
            class="stl_10 stl_08 stl_09"
          >、在收集您的个人信息后，我们会通过技术手段对您的个人信息进行去标识化处理。 &nbsp;</span></div>
        </div>
      </div>
    </div>
    <div class="stl_02">
      <div class="stl_03">
        <object data="兽医首选隐私政策_files/img_07.svg" type="image/svg+xml" class="stl_04"
                style="position:absolute; width:49.5833em; height:70.0833em;"
        >
          <embed src="兽医首选隐私政策_files/img_07.svg" type="image/svg+xml">
        </object>
      </div>
      <div class="stl_view">
        <div class="stl_05 stl_06">
          <div class="stl_01" style="left:0.5em;"><span class="stl_18 stl_08 stl_09">3</span><span
            class="stl_07 stl_08 stl_09"
          >、请您注意，您在使用我们的产品与</span><span class="stl_18 stl_08 stl_09">/</span><span
            class="stl_07 stl_08 stl_09"
          >或服务时所提供的所有个人信息，除非您删除或通过系统设 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;"><span
            class="stl_07 stl_08 stl_09"
          >置拒绝我们收集，否则将在您使用我们的产品与</span><span
            class="stl_18 stl_08 stl_09"
          >/</span><span class="stl_07 stl_08 stl_09">或服务期间持续授权我们使用。 &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">4</span><span
            class="stl_10 stl_08 stl_09"
          >、我们会对我们的产品与</span><span class="stl_11 stl_08 stl_09">/</span><span
            class="stl_10 stl_08 stl_09"
          >或服务使用情况进行统计，并可能会与公众或第三方共享这些统计信 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >息，以展示我们的产品与</span><span class="stl_11 stl_08 stl_09">/</span><span
            class="stl_10 stl_08 stl_09"
          >或服务的整体使用趋势，但这些统计信息不包含您的任何身份识别信息。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">5</span><span
            class="stl_10 stl_08 stl_09"
          >、当我们展示您的个人信息时，我们会采用包括内容替换、匿名处理方式对您的信息进行脱敏， &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">以保护您的信息安全。
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">6</span><span
            class="stl_10 stl_08 stl_09"
          >、当我们要将您的个人信息用于本政策未载明的其它用途时，或基于特定目的收集而来的信息用 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >于其他目的时，会通过您主动做出勾选的形式事先征求您的同意。</span><span
            class="stl_07 stl_08 stl_09"
          >请您理解，我们向您提供的产品与</span><span class="stl_18 stl_08 stl_09">/</span><span
            class="stl_07 stl_08 stl_09"
          >或 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >服务将不断更新变化。如果您选择使用本隐私政策中尚未列明的其他产品与</span><span
            class="stl_18 stl_08 stl_09"
          >/</span><span class="stl_07 stl_08 stl_09">或服务时，我们会在收集您 &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >的个人信息前通过协议、页面提示等方式向您详细说明信息收集的目的、方式、范围并征求您的同意。若 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >您不同意提供前述信息，您可能无法使用该项产品与</span><span
            class="stl_18 stl_08 stl_09"
          >/</span><span class="stl_07 stl_08 stl_09">或服务，但不影响您使用现有产品与</span><span
            class="stl_18 stl_08 stl_09"
          >/</span><span class="stl_07 stl_08 stl_09">或服务。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09"
                                                         style="word-spacing:0.25em;"
          >二、我们如何使用 </span><span class="stl_18 stl_08 stl_09"
                                 style="word-spacing:0.202em;"
          >Cookie </span><span class="stl_07 stl_08 stl_09">和同类技术 &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">（一）</span><span
            class="stl_18 stl_08 stl_09"
          >Cookie &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">1</span><span
            class="stl_10 stl_08 stl_09"
          >、为确保网站正常运转，为您获得更轻松的访问体验，向您推荐您可能感兴趣的内容，我们会在 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">您的计算机或移动设备上储存名为
          </span><span class="stl_11 stl_08 stl_09" style="word-spacing:-0.046em;">Cookie </span><span
            class="stl_10 stl_08 stl_09"
          >的小数据文件，</span><span class="stl_11 stl_08 stl_09"
                               style="word-spacing:-0.046em;"
          >Cookie </span><span class="stl_10 stl_08 stl_09">通常包含标识符、站点名称以及一些
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">号码和字符。借助于
          </span><span class="stl_11 stl_08 stl_09">Cookie</span><span
            class="stl_10 stl_08 stl_09"
          >，网站能够储存您的偏好或购物车</span><span class="stl_11 stl_08 stl_09">/</span><span
            class="stl_10 stl_08 stl_09"
          >购物袋内的商品等数据。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">2</span><span
            class="stl_10 stl_08 stl_09" style="word-spacing:0em;"
          >、您可根据自己的偏好管理或删除 </span><span
            class="stl_11 stl_08 stl_09"
          >Cookie</span><span class="stl_10 stl_08 stl_09">。您可以清除计算机上保存的所有
          </span><span class="stl_11 stl_08 stl_09">Cookie</span><span class="stl_10 stl_08 stl_09">，大部分网
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">络浏览器都设有阻止
          </span><span class="stl_11 stl_08 stl_09" style="word-spacing:-0.046em;">Cookie </span><span
            class="stl_10 stl_08 stl_09"
          >的功能。但如果您这么做，则需要在每一次访问我们的网站时更改用户设 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >置，也可能导致部分功能无法正常使用。如需了解如何更改浏览器设置，请访问您使用的浏览器的相关设 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">置页面。 &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">（二）网站信标和像素标签
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">除 </span><span
            class="stl_11 stl_08 stl_09" style="word-spacing:-0.046em;"
          >Cookie </span><span
            class="stl_10 stl_08 stl_09"
          >外，我们还会在网站上使用网站信标和像素标签等其他同类技术。例如，我们向您发送 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >的电子邮件可能含有链接至我们网站内容的地址链接，如果您点击该链接，我们则会跟踪此次点击，帮助 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >我们了解您的产品或服务偏好以便于我们主动改善客户服务体验。网站信标通常是一种嵌入到网站或电子 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >己的活动以这种方式被追踪，可以随时从我们的寄信名单中退订。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">（三）第三方 </span><span
            class="stl_18 stl_08 stl_09"
          >SDK</span><span class="stl_07 stl_08 stl_09">、插件 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">为保障我们的产品与</span><span
            class="stl_11 stl_08 stl_09"
          >/</span><span
            class="stl_10 stl_08 stl_09"
          >或服务稳定运行以及您能够充分享受和使用我们的产品与</span><span
            class="stl_11 stl_08 stl_09"
          >/</span><span class="stl_10 stl_08 stl_09">或服务的必需、附 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">加功能，我们的应用中会嵌入授权合作伙伴的
          </span><span class="stl_11 stl_08 stl_13" style="word-spacing:-0.0461em;">SDK </span><span
            class="stl_10 stl_08 stl_09"
          >或其他类似的应用程序。如您使用相关支付和金融服 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">务所必需的签约功能，需要调用契约锁的
          </span><span class="stl_11 stl_08 stl_09">SDK</span><span
            class="stl_10 stl_08 stl_09"
          >、插件功能，用于进行实名验证、核验身份。我们会对授 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >权合作伙伴获取有关信息的应用程序接口（</span><span
            class="stl_11 stl_08 stl_09"
          >API</span><span class="stl_10 stl_08 stl_09">）、软件工具开发包（</span><span
            class="stl_11 stl_08 stl_09"
          >SDK</span><span class="stl_10 stl_08 stl_09">）进行严格的安全检测，并对 &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >关键的第三方执行信息安全审计，以验证第三方的个人信息安全保护措施是否满足兽医首选</span><span
            class="stl_11 stl_08 stl_09"
          >™</span><span class="stl_10 stl_08 stl_09">的相关要 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >求。对于微信小程序所使用的插件，您可以通过进入小程序，依次点击右上角</span><span
            class="stl_11 stl_08 stl_09"
          >“...”</span><span class="stl_10 stl_08 stl_09">符号</span><span
            class="stl_11 stl_08 stl_09"
          >--</span><span class="stl_10 stl_08 stl_09">左下角的小程 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">序名称</span><span
            class="stl_11 stl_08 stl_09"
          >--“</span><span class="stl_10 stl_08 stl_09">更多资料</span><span
            class="stl_11 stl_08 stl_09"
          >”</span><span class="stl_10 stl_08 stl_09">，来查看服务商信息。 &nbsp;</span></div>
        </div>
      </div>
    </div>
    <div class="stl_02">
      <div class="stl_03">
        <object data="兽医首选隐私政策_files/img_08.svg" type="image/svg+xml" class="stl_04"
                style="position:absolute; width:49.5833em; height:70.0833em;"
        >
          <embed src="兽医首选隐私政策_files/img_08.svg" type="image/svg+xml">
        </object>
      </div>
      <div class="stl_view">
        <div class="stl_05 stl_06">
          <div class="stl_01" style="left:0.5em;"><span class="stl_07 stl_08 stl_09">三、我们如何共享、转让、公开披露您的个人信息
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">（一）共享 &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">1</span><span
            class="stl_10 stl_08 stl_09"
          >、我们不会与兽医首选</span><span class="stl_11 stl_08 stl_09">™</span><span
            class="stl_10 stl_08 stl_09"
          >以外的任何公司、组织和个人共享您的个人信息，但以下情况除外： &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">（</span><span
            class="stl_11 stl_08 stl_09"
          >1</span><span
            class="stl_10 stl_08 stl_09"
          >）在获取单独同意的情况下共享：获得您的单独同意后，我们会与其他方共享您的个人信息。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">（</span><span
            class="stl_11 stl_08 stl_09"
          >2</span><span
            class="stl_10 stl_08 stl_09"
          >）在法定情形下的共享：我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">机关依法提出的要求，对外共享您的个人信息。
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">（</span><span
            class="stl_11 stl_08 stl_09"
          >3</span><span
            class="stl_10 stl_08 stl_09"
          >）在法律法规允许的范围内，为维护兽医首选</span><span
            class="stl_11 stl_08 stl_09"
          >™</span><span class="stl_10 stl_08 stl_09">、兽医首选</span><span
            class="stl_11 stl_08 stl_09"
          >™</span><span class="stl_10 stl_08 stl_09">的关联方或合作伙伴、您或其他兽 &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">医首选</span><span
            class="stl_11 stl_08 stl_09"
          >™</span><span class="stl_10 stl_08 stl_09">用户或社会公众利益、财产或安全免遭损害而有必要提供。
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">（</span><span
            class="stl_11 stl_08 stl_09"
          >4</span><span
            class="stl_10 stl_08 stl_09"
          >）只有共享您的信息，才能实现我们的产品与</span><span
            class="stl_11 stl_08 stl_09"
          >/</span><span class="stl_10 stl_08 stl_09">或服务的核心功能或提供您需要的服务。
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">（</span><span
            class="stl_11 stl_08 stl_09"
          >5</span><span class="stl_10 stl_08 stl_09">）应您需求为您处理您与他人的纠纷或争议。
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">（</span><span
            class="stl_11 stl_08 stl_09"
          >6</span><span
            class="stl_10 stl_08 stl_09"
          >）符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">件约定所提供。 &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">（</span><span
            class="stl_11 stl_08 stl_09"
          >7</span><span class="stl_10 stl_08 stl_09">）基于学术研究而使用。 &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">（</span><span
            class="stl_11 stl_08 stl_09"
          >8</span><span class="stl_10 stl_08 stl_09">）基于符合法律法规的社会公共利益而使用。
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">2</span><span
            class="stl_10 stl_08 stl_09"
          >、</span><span
            class="stl_07 stl_08 stl_09"
          >基于向您完整提供我们的产品和服务的需要，我们可能会将您的个人信息与我们的关联方或关 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >联产品共享。但我们只会共享必要的个人信息，且受本隐私政策中所声明范围和目的的约束。我们的关联 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >方如要改变个人信息的处理目的、范围，将再次征求您的授权同意。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">（</span><span
            class="stl_11 stl_08 stl_09"
          >1</span><span class="stl_10 stl_08 stl_09">）当您购买的是兽医首选</span><span
            class="stl_11 stl_08 stl_09"
          >™</span><span class="stl_10 stl_08 stl_09">自营的商品或服务时，兽医首选</span><span
            class="stl_11 stl_08 stl_09"
          >™</span><span class="stl_10 stl_08 stl_09">的关联方将需要依法为您开具相应 &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >发票，为此需要将您的</span><span
            class="stl_07 stl_08 stl_09"
          >订单号、订单商品名称、商品数量以及商品金额信息</span><span
            class="stl_10 stl_08 stl_09"
          >共享给相应的开票关联方。关 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >于开具发票的兽医首选</span><span class="stl_11 stl_08 stl_09">™</span><span
            class="stl_10 stl_08 stl_09"
          >关联方，您可以通过兽医首选</span><span class="stl_11 stl_08 stl_09">™</span><span
            class="stl_10 stl_08 stl_09"
          >平台公示的经营主体证照进行查询；您也可以在 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">交易完成后通过</span><span
            class="stl_11 stl_08 stl_09"
          >“</span><span class="stl_10 stl_08 stl_09">我的</span><span
            class="stl_11 stl_08 stl_09"
          >”—“</span><span class="stl_10 stl_08 stl_09">客服</span><span
            class="stl_11 stl_08 stl_09"
          >”</span><span class="stl_10 stl_08 stl_09">咨询具体的开票方。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">（</span><span
            class="stl_11 stl_08 stl_09"
          >2</span><span class="stl_10 stl_08 stl_09">）当您使用兽医首选</span><span
            class="stl_11 stl_08 stl_09"
          >™</span><span class="stl_10 stl_08 stl_09">账号登录兽医首选</span><span
            class="stl_11 stl_08 stl_09"
          >™</span><span
            class="stl_10 stl_08 stl_09"
          >旗下前店、后仓等业务产品时，您同意将您的</span><span class="stl_07 stl_08 stl_09">手机号
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">码、收货地址信息</span><span
            class="stl_10 stl_08 stl_09"
          >共享至此类业务产品，以便您快速使用此类业务产品的必要场景功能；并在您已关联微 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">信时，通过微信公众号向您告知订单服务进度。
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">3</span><span
            class="stl_10 stl_08 stl_09"
          >、与授权合作伙伴共享：</span><span
            class="stl_07 stl_08 stl_09"
          >仅为实现本隐私政策中声明的目的，我们的某些服务将由我们和授权合 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >作伙伴共同提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。</span><span
            class="stl_10 stl_08 stl_09"
          >例 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >如，在您上网购买我们的产品时，我们必须与物流服务提供商共享您的个人信息才能安排送货，或者安排 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >合作伙伴提供服务。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >享提供服务所必要的个人信息。</span><span
            class="stl_07 stl_08 stl_09"
          >我们的合作伙伴无权将共享的个人信息用于与产品或服务无关的其他用 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">途。</span><span
            class="stl_10 stl_08 stl_09"
          >目前，我们的合作伙伴包括以下类型： &nbsp;</span></div>
        </div>
      </div>
    </div>
    <div class="stl_02">
      <div class="stl_03">
        <object data="兽医首选隐私政策_files/img_09.svg" type="image/svg+xml" class="stl_04"
                style="position:absolute; width:49.5833em; height:70.0833em;"
        >
          <embed src="兽医首选隐私政策_files/img_09.svg" type="image/svg+xml">
        </object>
      </div>
      <div class="stl_view">
        <div class="stl_05 stl_06">
          <div class="stl_01" style="left:0.5em;"><span class="stl_10 stl_08 stl_09">（</span><span
            class="stl_11 stl_08 stl_09"
          >1</span><span
            class="stl_10 stl_08 stl_09"
          >）商品或技术服务的供应商。我们可能会将您的个人信息共享给支持我们功能的第三方。这些 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;"><span
            class="stl_10 stl_08 stl_09"
          >支持包括为我们的供货或提供基础设施技术服务、物流配送服务、支付服务、数据处理等。我们共享这些 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >信息的目的是可以实现我们产品与</span><span class="stl_11 stl_08 stl_09">/</span><span
            class="stl_10 stl_08 stl_09"
          >或服务的核心购物功能，比如我们必须与物流服务提供商共享您的订 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >单信息才能安排送货（</span><span class="stl_07 stl_08 stl_09">您可以通过</span><span
            class="stl_18 stl_08 stl_09"
          >“</span><span class="stl_07 stl_08 stl_09">我的订单</span><span
            class="stl_18 stl_08 stl_09"
          >”—“</span><span class="stl_07 stl_08 stl_09">订单详情</span><span
            class="stl_18 stl_08 stl_09"
          >”</span><span class="stl_07 stl_08 stl_09">实时查阅具体服务方信息</span><span
            class="stl_10 stl_08 stl_09"
          >）；或者我们需 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">要将您的</span><span
            class="stl_07 stl_08 stl_09"
          >订单号和订单金额</span><span
            class="stl_10 stl_08 stl_09"
          >与第三方支付机构共享以实现其确认您的支付指令并完成支付等。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">（</span><span
            class="stl_11 stl_08 stl_09"
          >2</span><span class="stl_10 stl_08 stl_09">）第三方商家。</span><span
            class="stl_07 stl_08 stl_09"
          >我们必须将您的订单信息与交易有关的必要信息（包括商品名称、订单号、金 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >额、数量、收货人姓名、收货地址、收货人联系方式）与第三方商家共享来实现您向其购买商品或服务的 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >需求，并促使其可以完成后续的售后服务。为向您提供售后与争议解决服务之目的，您同意我们可自上述 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">服务方收集与物流服务相关的信息。
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">（</span><span
            class="stl_11 stl_08 stl_09"
          >3</span><span
            class="stl_10 stl_08 stl_09"
          >）广告与推广的合作伙伴。当合作伙伴向使用我们产品与</span><span
            class="stl_11 stl_08 stl_09"
          >/</span><span class="stl_10 stl_08 stl_09">或服务的用户群提供促销推广的服务 &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">时，</span><span
            class="stl_07 stl_08 stl_09"
          >我们会向这些合作伙伴提供不能识别你个人身份的间接画像标签及去标识化的设备信息或匿名化后的 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >设备、网络、渠道信息，并共享去标识化的统计分析信息，以提供程序化广告服务。这些信息仅为提高推 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >广的覆盖面以及有效触达率，不会识别您的真实身份或与您的真实身份相关联</span><span
            class="stl_10 stl_08 stl_09"
          >。</span><span class="stl_07 stl_08 stl_09">我们也会与第三方的平台 &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >或媒体合作基于您的个人信息向您推荐个性化的商品或服务。这些个人信息来源于您在使用我们产品与</span><span
            class="stl_18 stl_08 stl_09"
          >/ &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >或服务过程中的行为数据及其间接画像，该过程中我们不会向平台或媒体提供可用于识别你个人身份的信 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">息。 &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">（</span><span
            class="stl_11 stl_08 stl_09"
          >4</span><span
            class="stl_10 stl_08 stl_09"
          >）合作金融机构或其他合作伙伴。当您通过兽医首选</span><span
            class="stl_11 stl_08 stl_09"
          >™</span><span class="stl_10 stl_08 stl_09">平台使用消费金融服务时，基于反洗钱等
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >相关法律法规规定以及签署、履行服务合同的要求，</span><span
            class="stl_07 stl_08 stl_09"
          >您需要提供姓名、身份证或其他身份证明（如护 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >照）、银行卡号及其绑定手机号、人脸信息、常用地址、联系人信息，并将其共享给提供服务的第三方， &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >根据提供服务的第三方具体要求，您还可能需要主动提供职业、婚姻状况、最高学历、居住状况、月收 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >入、单位名称、单位地址、单位电话信息。您在使用该等服务时，我们会充分地向您明示，仅当获得您的 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >单独同意后，我们方会向该服务提供方共享您的个人信息</span><span class="stl_10 stl_08 stl_09">。
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">4</span><span
            class="stl_10 stl_08 stl_09"
          >、为了遵守法律、执行或适用我们的使用条件和其他协议，或者为了保护兽医首选</span><span
            class="stl_11 stl_08 stl_09"
          >™</span><span class="stl_10 stl_08 stl_09">、您或其他兽 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">医首选</span><span
            class="stl_11 stl_08 stl_09"
          >™</span><span
            class="stl_10 stl_08 stl_09"
          >用户的权利及其财产或安全，比如为防止欺诈等违法活动和减少信用风险，而与其他公司和组织 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >交换信息。不过，这并不包括违反本隐私政策中所作的承诺而为获利目的出售、出租、共享或以其它方式 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">披露的个人信息。
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">5</span><span
            class="stl_10 stl_08 stl_09"
          >、对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。在敏感个人信息使用 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >上，我们要求第三方采用数据脱敏和加密技术，从而更好地保护用户个人信息。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">（二）转让 &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外： &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">1</span><span
            class="stl_10 stl_08 stl_09"
          >、在获取单独同意的情况下转让：获得您的单独同意后，我们会向其他方转让您的个人信息； &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">2</span><span
            class="stl_10 stl_08 stl_09"
          >、根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供； &nbsp;</span></div>
        </div>
      </div>
    </div>
    <div class="stl_02">
      <div class="stl_03">
        <object data="兽医首选隐私政策_files/img_10.svg" type="image/svg+xml" class="stl_04"
                style="position:absolute; width:49.5833em; height:70.0833em;"
        >
          <embed src="兽医首选隐私政策_files/img_10.svg" type="image/svg+xml">
        </object>
      </div>
      <div class="stl_view">
        <div class="stl_05 stl_06">
          <div class="stl_01" style="left:0.5em;"><span class="stl_11 stl_08 stl_09">3</span><span
            class="stl_10 stl_08 stl_09"
          >、符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;"><span class="stl_10 stl_08 stl_09">约定所提供； &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">4</span><span
            class="stl_10 stl_08 stl_09"
          >、</span><span
            class="stl_07 stl_08 stl_09"
          >在涉及合并、收购、资产转让或类似的交易时，如涉及到个人信息转让，我们会要求新的持有 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >您个人信息的公司、组织继续受本隐私政策的约束，否则，我们将要求该公司、组织重新向您征求授权同 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">意。 &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">（三）公开披露 &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >我们仅会在以下情况下，才会公开披露您的个人信息： &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">1</span><span
            class="stl_10 stl_08 stl_09"
          >、获得您单独同意或基于您的主动选择，我们可能会公开披露您的个人信息； &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">2</span><span
            class="stl_10 stl_08 stl_09"
          >、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律法规的前提下，当我们 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查函。我们坚信，对 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。在法律法规许 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >可的前提下，我们披露的文件均在加密密钥的保护之下。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">四、我们如何保护和保存您的个人信息
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">（一）个人信息保护的技术与措施
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">1</span><span
            class="stl_10 stl_08 stl_09"
          >、数据安全技术措施 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >我们已采取符合业界标准、合理可行的安全防护措施保护您提供的个人信息安全，防止个人信息遭 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会使用加密技术（如 </span><span
            class="stl_11 stl_08 stl_09"
          >TLS</span><span class="stl_10 stl_08 stl_09">、</span><span
            class="stl_11 stl_08 stl_20" style="word-spacing:-0.0451em;"
          >AES </span><span
            class="stl_10 stl_08 stl_09"
          >等）、脱敏 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >技术和匿名化技术提高个人信息的安全性；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；我 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息；部署数据安全监控机制，尽力确保数 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >据在数据生命周期（数据采集、存储、传输、使用、共享和销毁）中得到安全保障。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">2</span><span
            class="stl_10 stl_08 stl_09"
          >、其他安全措施 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >我们通过建立专门的管理制度、流程和组织以保障个人信息的存储和使用安全。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >我们通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >我们建立信息安全委员会并下设信息保护专职部门、数据安全应急响应组织来推进和保障个人信息 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">安全。 &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >加强安全意识。我们建立了信息安全培训体系，通过多种手段对全体员工进行信息安全意识培训， &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >提高员工的信息安全意识以及隐私安全意识，保障用户个人信息安全。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">安全认证。兽医首选</span><span
            class="stl_11 stl_08 stl_09"
          >™</span><span class="stl_10 stl_08 stl_09">网站和支付系统均采用 </span><span
            class="stl_11 stl_08 stl_12" style="word-spacing:-0.0455em;"
          >HTTPS </span><span
            class="stl_10 stl_08 stl_09"
          >加密</span><span class="stl_11 stl_08 stl_09">/</span><span
            class="stl_10 stl_08 stl_09"
          >身份验证层，确保信息传输过程中不被 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">拦截、破解、修改。
            &nbsp;</span></div>
        </div>
      </div>
    </div>
    <div class="stl_02">
      <div class="stl_03">
        <object data="兽医首选隐私政策_files/img_11.svg" type="image/svg+xml" class="stl_04"
                style="position:absolute; width:49.5833em; height:70.0833em;"
        >
          <embed src="兽医首选隐私政策_files/img_11.svg" type="image/svg+xml">
        </object>
      </div>
      <div class="stl_view">
        <div class="stl_05 stl_06">
          <div class="stl_01" style="left:0.5em;"><span class="stl_11 stl_08 stl_09">3</span><span
            class="stl_10 stl_08 stl_09"
          >、我们仅允许有必要知晓这些信息的兽医首选</span><span
            class="stl_11 stl_08 stl_09"
          >™</span><span class="stl_10 stl_08 stl_09">及兽医首选</span><span
            class="stl_11 stl_08 stl_09"
          >™</span><span class="stl_10 stl_08 stl_09">关联方的员工、合作伙伴访问个人信 &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;"><span
            class="stl_10 stl_08 stl_09"
          >息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您个人信息的所有人员履行 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;"><span
            class="stl_10 stl_08 stl_09"
          >相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与兽医首选</span><span
            class="stl_11 stl_08 stl_09"
          >™</span><span class="stl_10 stl_08 stl_09">的合作关系。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">4</span><span
            class="stl_10 stl_08 stl_09"
          >、我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本政策所述目 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。例如《中华人民共和国电子 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。在您的个人信息超出保 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >留期间后，我们会根据适用法律的要求删除您的个人信息，或使其匿名化处理。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">5</span><span
            class="stl_10 stl_08 stl_09"
          >、我们大力强化对兽医首选</span><span class="stl_11 stl_08 stl_09">™</span><span
            class="stl_10 stl_08 stl_09"
          >物流员工的数据安全培训和要求，提高物流员工保护用户隐私数据的 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >意识；每年定期对兽医首选</span><span class="stl_11 stl_08 stl_09">™</span><span
            class="stl_10 stl_08 stl_09"
          >物流仓进行信息安全巡检，确保信息安全保障措施满足安全要求。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">6</span><span
            class="stl_10 stl_08 stl_09"
          >、互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件等与其他用户的交流方式无 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的个人信息安全。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">7</span><span
            class="stl_10 stl_08 stl_09"
          >、互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >致您的合法权益受损，我们将承担相应的法律责任。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">8</span><span
            class="stl_10 stl_08 stl_09"
          >、安全应急响应。当您在兽医首选</span><span class="stl_11 stl_08 stl_09">™</span><span
            class="stl_10 stl_08 stl_09"
          >网站与第三方进行网上商品或服务的交易时，您不可避免的要 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或者邮政地址等。请您妥善保护自己的个 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">人信息，仅在必要的情形下向他人提供。
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >针对个人信息泄露、毁损、丢失等安全风险，我们建立了安全应急响应机制和应急响应团队，从制 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >度层面规范安全事件处置的要求和流程。对安全事件实施分类分级，针对不同事件启动有针对性的安全应 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >急预案，进行事件检测、抑制、根除、恢复、制定补救措施，联合相关部门进行溯源和打击。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">9</span><span
            class="stl_10 stl_08 stl_09"
          >、在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动向监管 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">部门上报个人信息安全事件的处置情况。
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">10</span><span
            class="stl_10 stl_08 stl_09"
          >、如果您对我们的个人信息保护有任何疑问，可通过本政策最下方约定的联系方式联系我们。如 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >您发现自己的个人信息泄露，尤其是您的账户及密码发生泄露，请您立即通过本政策最下方【如何联系我 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >们】约定的联系方式联络我们，以便我们采取相应措施。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">（二）个人信息的保存
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">1</span><span
            class="stl_10 stl_08 stl_09"
          >、您的个人信息将被存储于中华人民共和国境内。如您使用跨境交易服务，且需要向境外传输您 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >的个人信息完成交易的，我们会要求接收方按照我们的说明、本隐私政策以及其他任何相关的保密和安全 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">措施来处理这些个人信息。
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">2</span><span
            class="stl_10 stl_08 stl_09"
          >、</span><span class="stl_07 stl_08 stl_09">请您注意，当您成功申请注销兽医首选</span><span
            class="stl_18 stl_08 stl_09"
          >™</span><span class="stl_07 stl_08 stl_09">账户后，我们将对您的个人信息进行删除或匿名化处
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">理。 &nbsp;</span>
          </div>
        </div>
      </div>
    </div>
    <div class="stl_02">
      <div class="stl_03">
        <object data="兽医首选隐私政策_files/img_12.svg" type="image/svg+xml" class="stl_04"
                style="position:absolute; width:49.5833em; height:70.0833em;"
        >
          <embed src="兽医首选隐私政策_files/img_12.svg" type="image/svg+xml">
        </object>
      </div>
      <div class="stl_view">
        <div class="stl_05 stl_06">
          <div class="stl_01" style="left:0.5em;"><span class="stl_11 stl_08 stl_09">3</span><span
            class="stl_10 stl_08 stl_09"
          >、</span><span
            class="stl_07 stl_08 stl_09"
          >如果我们终止服务或运营，我们会至少提前三十日向您通知，并在终止服务或运营后对您的个 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;"><span class="stl_07 stl_08 stl_09">人信息进行删除或匿名化处理。
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_18 stl_08 stl_09">4</span><span
            class="stl_07 stl_08 stl_09"
          >、我们只会在实现本隐私政策所述目的所需的期限内保留您的个人信息，法律有强制的留存要求 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >的除外，例如《电子商务法》规定商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">五、您如何管理您的个人信息
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">您可以通过以下方式访问及管理您的个人信息：
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">1</span><span
            class="stl_10 stl_08 stl_09"
          >、查阅和更正您的个人信息 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >您有权随时查阅和更正您的个人信息，法律法规规定的例外情况除外，您可以通过以下方式查阅和 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">更正您的个人信息：
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">（</span><span
            class="stl_11 stl_08 stl_09"
          >1</span><span class="stl_10 stl_08 stl_09">）账户信息：移动端，您可以在</span><span
            class="stl_11 stl_08 stl_09"
          >“</span><span class="stl_10 stl_08 stl_09">我的</span><span
            class="stl_11 stl_08 stl_09"
          >—</span><span class="stl_10 stl_08 stl_09">完善资料</span><span
            class="stl_11 stl_08 stl_09"
          >”</span><span class="stl_10 stl_08 stl_09">界面查阅您的个人信息，您可以通过</span><span
            class="stl_11 stl_08 stl_09"
          >“</span><span class="stl_10 stl_08 stl_09">我 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">的</span><span
            class="stl_11 stl_08 stl_09"
          >—</span><span class="stl_10 stl_08 stl_09">完善资料</span><span
            class="stl_11 stl_08 stl_09"
          >”</span><span
            class="stl_10 stl_08 stl_09"
          >设置您的个人资料（包括头姓名、行业分类、手机号、公司名称、职位、所属地区等信 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">息）；您还可以通过</span><span
            class="stl_11 stl_08 stl_09"
          >“</span><span class="stl_10 stl_08 stl_09">我的</span><span
            class="stl_11 stl_08 stl_09"
          >—</span><span class="stl_10 stl_08 stl_09">客服</span><span
            class="stl_11 stl_08 stl_09" style="word-spacing:0.2041em;"
          >” </span><span
            class="stl_10 stl_08 stl_09"
          >联系我们客服处理修改您的登录名，修改您的绑定的手机号、验证 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">邮箱，管理您的关联账号信息。
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">（</span><span
            class="stl_11 stl_08 stl_09"
          >2</span><span class="stl_10 stl_08 stl_09">）订单信息：您可以通过访问</span><span
            class="stl_11 stl_08 stl_09"
          >“</span><span class="stl_10 stl_08 stl_09">我的</span><span
            class="stl_11 stl_08 stl_09"
          >—</span><span class="stl_10 stl_08 stl_09">我的订单</span><span
            class="stl_11 stl_08 stl_09"
          >”</span><span class="stl_10 stl_08 stl_09">页面查看您所有订单及其状态（包括已完
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">成、已取消的）。
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">（</span><span
            class="stl_11 stl_08 stl_09"
          >3</span><span class="stl_10 stl_08 stl_09">）您可以通过</span><span
            class="stl_11 stl_08 stl_09"
          >“</span><span class="stl_10 stl_08 stl_09">我的</span><span
            class="stl_11 stl_08 stl_09"
          >—</span><span class="stl_10 stl_08 stl_09">商品收藏</span><span
            class="stl_11 stl_08 stl_09"
          >”</span><span class="stl_10 stl_08 stl_09">管理您的收藏信息。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">（</span><span
            class="stl_11 stl_08 stl_09"
          >4</span><span class="stl_10 stl_08 stl_09">）您在使用我们的产品与</span><span
            class="stl_11 stl_08 stl_09"
          >/</span><span class="stl_10 stl_08 stl_09">或服务过程中产生的其他个人信息需要访问或更正，请随时联系我
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >们。我们会根据本隐私政策所列明的方式和期限响应您的请求。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">（</span><span
            class="stl_11 stl_08 stl_09"
          >5</span><span
            class="stl_10 stl_08 stl_09"
          >）无法查阅和更正的个人信息：除上述列明的信息外，您的部分个人信息我们还无法为您提供 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >查阅和更正的服务，这些信息主要是为了提升您的用户体验和保证交易安全所收集的您的设备信息、您使 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >用附加功能时产生的非您主动提交的个人信息。上述信息我们会在您的授权范围内进行使用，您无法查阅 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >和更正，但您可联系我们进行删除或做匿名化处理。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">2</span><span
            class="stl_10 stl_08 stl_09"
          >、删除您的个人信息 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">您在我们的产品与</span><span
            class="stl_11 stl_08 stl_09"
          >/</span><span
            class="stl_10 stl_08 stl_09"
          >或服务页面中可以直接清除或删除的信息，包括订单信息、收货地址信息； &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >在以下情形中，您可以向我们提出删除个人信息的请求： &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">（</span><span
            class="stl_11 stl_08 stl_09"
          >1</span><span class="stl_10 stl_08 stl_09">）如果我们处理个人信息的行为违反法律法规；
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">（</span><span
            class="stl_11 stl_08 stl_09"
          >2</span><span class="stl_10 stl_08 stl_09">）如果我们收集、使用您的个人信息，却未征得您的同意；
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">（</span><span
            class="stl_11 stl_08 stl_09"
          >3</span><span class="stl_10 stl_08 stl_09">）如果我们处理个人信息的行为违反了与您的约定；
            &nbsp;</span></div>
        </div>
      </div>
    </div>
    <div class="stl_02">
      <div class="stl_03">
        <object data="兽医首选隐私政策_files/img_13.svg" type="image/svg+xml" class="stl_04"
                style="position:absolute; width:49.5833em; height:70.0833em;"
        >
          <embed src="兽医首选隐私政策_files/img_13.svg" type="image/svg+xml">
        </object>
      </div>
      <div class="stl_view">
        <div class="stl_05 stl_06">
          <div class="stl_01" style="left:0.5em;"><span class="stl_10 stl_08 stl_09">（</span><span
            class="stl_11 stl_08 stl_09"
          >4</span><span class="stl_10 stl_08 stl_09">）如果您注销了兽医首选</span><span
            class="stl_11 stl_08 stl_09"
          >™</span><span class="stl_10 stl_08 stl_09">账户； &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">（</span><span
            class="stl_11 stl_08 stl_09"
          >5</span><span class="stl_10 stl_08 stl_09">）如果我们终止服务及运营； &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">（</span><span
            class="stl_11 stl_08 stl_09"
          >6</span><span class="stl_10 stl_08 stl_09">）如果我们保存您个人信息的期限已届满。
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >除，除非法律法规另有规定，或这些实体获得您的独立授权。当您从我们的服务中删除信息后，我们可能 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">3</span><span
            class="stl_10 stl_08 stl_09"
          >、</span><span class="stl_07 stl_08 stl_09">改变您授权同意的范围或撤回您的授权
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">您可以通过以下方式改变或者撤回您的授权：
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">（</span><span
            class="stl_18 stl_08 stl_09"
          >1</span><span
            class="stl_07 stl_08 stl_09"
          >）您可以通过删除信息、关闭设备功能、在兽医首选</span><span
            class="stl_18 stl_08 stl_09"
          >™</span><span class="stl_07 stl_08 stl_09">网站或软件中进行</span><span
            class="stl_18 stl_08 stl_09"
          >“</span><span class="stl_07 stl_08 stl_09">隐私设置</span><span
            class="stl_18 stl_08 stl_09"
          >”</span><span class="stl_07 stl_08 stl_09">等方式改 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >变您授权我们继续收集个人信息的范围或撤回您的授权。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">（</span><span
            class="stl_18 stl_08 stl_09"
          >2</span><span class="stl_07 stl_08 stl_09"
                         style="word-spacing:0.0239em;"
          >） 根据您所使用的具体服务，可以通过发送邮件到 </span><a href="mailto:office@petcare.cn"
                                             target="_blank"
          ><span class="stl_18 stl_08 stl_09" style="word-spacing:-0.0483em;">office@petcare.cn
          </span></a><span class="stl_07 stl_08 stl_09">进行相关请求及操 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >作。我们将会在您做出请求后的合理时间内处理您的请求，并且会根据您的请求，在此后不再收集、使用 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">和</span><span
            class="stl_18 stl_08 stl_09"
          >/</span><span class="stl_07 stl_08 stl_09">或披露您的个人信息。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">（</span><span
            class="stl_18 stl_08 stl_09"
          >3</span><span
            class="stl_07 stl_08 stl_09"
          >）您也可以通过注销账户的方式，撤回我们继续收集您个人信息的全部授权。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >请您理解，每个业务功能需要一些基本的个人信息才能得以完成，当您撤回同意或授权后，我们无 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >法继续为您提供撤回同意或授权所对应的服务，也不再处理您相应的个人信息。但您撤回同意或授权的决 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >定，不会影响此前基于您的授权而开展的个人信息处理。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">4</span><span
            class="stl_10 stl_08 stl_09"
          >、个人信息主体注销账户 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">您可以通过移动端</span><span
            class="stl_11 stl_08 stl_09"
          >“</span><span class="stl_10 stl_08 stl_09">我的</span><span
            class="stl_11 stl_08 stl_09"
          >—</span><span class="stl_10 stl_08 stl_09">客服</span><span
            class="stl_11 stl_08 stl_09"
          >”</span><span class="stl_10 stl_08 stl_09">联系客服申请注销账户。您注销账户后，我们将停止向您提供产
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">品与</span><span
            class="stl_11 stl_08 stl_09"
          >/</span><span class="stl_10 stl_08 stl_09">或服务，除法律法规另有规定外，我们将删除您的个人信息。
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">5</span><span
            class="stl_10 stl_08 stl_09"
          >、如果您不想接受我们给您发送的促销信息，您可以随时回复</span><span
            class="stl_11 stl_08 stl_09"
          >“TD”</span><span class="stl_10 stl_08 stl_09">来取消我们给您发送的手机 &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >促销短信。为了保护您的隐私，我们不会以任何方式和途径向您推送涉及宗教信仰、性、疾病等相关敏感 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">内容的促销或商品信息给您。
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">6</span><span
            class="stl_10 stl_08 stl_09"
          >、响应您的上述请求 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >如果您无法通过上述方式查阅、更正或删除您的个人信息，或您需要查阅、更正或删除您在使用我 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">们产品与</span><span
            class="stl_11 stl_08 stl_09"
          >/</span><span
            class="stl_10 stl_08 stl_09"
          >或服务时所产生的其他个人信息，或您认为兽医首选</span><span
            class="stl_11 stl_08 stl_09"
          >™</span><span class="stl_10 stl_08 stl_09">存在任何违反法律法规或与您关于个人
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >信息的收集或使用的约定，您均可以通过本协议下方的方式与我们联系。为了保障安全，我们可能需要您 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >提供书面请求，或以其他方式证明您的身份，我们将在收到您反馈并验证您的身份后的 </span><span
            class="stl_11 stl_08 stl_14" style="word-spacing:-0.0446em;"
          >15 </span><span
            class="stl_10 stl_08 stl_09"
          >天内答复您的 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >请求。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况 &nbsp;</span></div>
        </div>
      </div>
    </div>
    <div class="stl_02">
      <div class="stl_03">
        <object data="兽医首选隐私政策_files/img_14.svg" type="image/svg+xml" class="stl_04"
                style="position:absolute; width:49.5833em; height:70.0833em;"
        >
          <embed src="兽医首选隐私政策_files/img_14.svg" type="image/svg+xml">
        </object>
      </div>
      <div class="stl_view">
        <div class="stl_05 stl_06">
          <div class="stl_01" style="left:0.5em;"><span
            class="stl_10 stl_08 stl_09"
          >收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;"><span
            class="stl_10 stl_08 stl_09"
          >惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;"><span class="stl_10 stl_08 stl_09">可能会予以拒绝。 &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >在以下情形中，按照法律法规要求，我们将无法响应您的请求： &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">（</span><span
            class="stl_11 stl_08 stl_09"
          >1</span><span class="stl_10 stl_08 stl_09">）与国家安全、国防安全有关的； &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">（</span><span
            class="stl_11 stl_08 stl_09"
          >2</span><span class="stl_10 stl_08 stl_09">）与公共安全、公共卫生、重大公共利益有关的；
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">（</span><span
            class="stl_11 stl_08 stl_09"
          >3</span><span class="stl_10 stl_08 stl_09">）与犯罪侦查、起诉和审判等有关的；
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">（</span><span
            class="stl_11 stl_08 stl_09"
          >4</span><span class="stl_10 stl_08 stl_09">）有充分证据表明您存在主观恶意或滥用权利的；
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">（</span><span
            class="stl_11 stl_08 stl_09"
          >5</span><span
            class="stl_10 stl_08 stl_09"
          >）响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">六、您的个人信息如何在全球范围转移
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >原则上，我们在中华人民共和国境内运营中收集和产生的个人信息，将存储在中华人民共和国境 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">内，以下情形除外：
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">（</span><span
            class="stl_11 stl_08 stl_09"
          >1</span><span class="stl_10 stl_08 stl_09">）适用的法律有明确规定； &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">（</span><span
            class="stl_11 stl_08 stl_09"
          >2</span><span class="stl_10 stl_08 stl_09">）获得您的单独同意； &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">（</span><span
            class="stl_11 stl_08 stl_09"
          >3</span><span class="stl_10 stl_08 stl_09">）您通过互联网进行跨境交易等个人主动行为。
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >针对以上情形，我们会确保依据本隐私政策对您的个人信息提供足够的保护。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">七、通知和修订 &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">1</span><span
            class="stl_10 stl_08 stl_09"
          >、为给您提供更好的服务以及随着兽医首选</span><span
            class="stl_11 stl_08 stl_09"
          >™</span><span class="stl_10 stl_08 stl_09">业务的发展，本隐私政策也会随之更新。但未经您明
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >确同意，我们不会削减您依据本隐私政策所应享有的权利。我们会通过在兽医首选</span><span
            class="stl_11 stl_08 stl_09"
          >™</span><span class="stl_10 stl_08 stl_09">网站、兽医首选</span><span
            class="stl_11 stl_08 stl_09"
          >™</span><span class="stl_10 stl_08 stl_09">移 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >动端上发出更新版本并在生效前通过网站公告或以其他适当方式提醒您相关内容的更新，也请您访问兽医 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">首选</span><span
            class="stl_11 stl_08 stl_09"
          >™</span><span class="stl_10 stl_08 stl_09">以便及时了解最新的隐私政策。 &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">2</span><span
            class="stl_10 stl_08 stl_09"
          >、对于重大变更，我们还会提供更为显著的通知（我们会通过包括但不限于邮件、短信或在浏览 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >页面做特别提示等方式，说明隐私政策的具体变更内容）。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">本政策所指的重大变更包括但不限于：
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">（</span><span
            class="stl_11 stl_08 stl_09"
          >1</span><span
            class="stl_10 stl_08 stl_09"
          >）我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">使用方式等； &nbsp;</span>
          </div>
        </div>
      </div>
    </div>
    <div class="stl_02">
      <div class="stl_03">
        <object data="兽医首选隐私政策_files/img_15.svg" type="image/svg+xml" class="stl_04"
                style="position:absolute; width:49.5833em; height:70.0833em;"
        >
          <embed src="兽医首选隐私政策_files/img_15.svg" type="image/svg+xml">
        </object>
      </div>
      <div class="stl_view">
        <div class="stl_05 stl_06">
          <div class="stl_01" style="left:0.5em;"><span class="stl_10 stl_08 stl_09">（</span><span
            class="stl_11 stl_08 stl_09"
          >2</span><span
            class="stl_10 stl_08 stl_09"
          >）我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;"><span class="stl_10 stl_08 stl_09">变更等； &nbsp;</span>
          </div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">（</span><span
            class="stl_11 stl_08 stl_09"
          >3</span><span class="stl_10 stl_08 stl_09">）个人信息共享、转让或公开披露的主要对象发生变化；
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">（</span><span
            class="stl_11 stl_08 stl_09"
          >4</span><span class="stl_10 stl_08 stl_09">）您参与个人信息处理方面的权利及其行使方式发生重大变化；
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">（</span><span
            class="stl_11 stl_08 stl_09"
          >5</span><span
            class="stl_10 stl_08 stl_09"
          >）我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时； &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">（</span><span
            class="stl_11 stl_08 stl_09"
          >6</span><span class="stl_10 stl_08 stl_09">）个人信息安全影响评估报告表明存在高风险时。
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">3</span><span
            class="stl_10 stl_08 stl_09"
          >、我们还会将本策略的旧版本存档，供您查阅。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">4</span><span
            class="stl_10 stl_08 stl_09"
          >、</span><span
            class="stl_07 stl_08 stl_09"
          >为了您能及时接收到通知，建议您在联系方式更新时及时通知我们。如您在本政策更新生效后 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >继续使用我们的服务，即代表您已充分阅读、理解并接受更新后的政策并愿意受更新后的政策约束。我们 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >鼓励您在每次使用我们服务时都查阅本政策。您可以在我们的产品和服务中通过</span><span
            class="stl_18 stl_08 stl_09"
          >“</span><span class="stl_07 stl_08 stl_09">我的</span><span
            class="stl_18 stl_08 stl_09"
          >--</span><span class="stl_07 stl_08 stl_09">设置</span><span
            class="stl_18 stl_08 stl_09"
          >--</span><span class="stl_07 stl_08 stl_09">隐私</span><span
            class="stl_18 stl_08 stl_09"
          >--</span><span class="stl_07 stl_08 stl_09">隐 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">私政策</span><span
            class="stl_18 stl_08 stl_09"
          >”</span><span class="stl_07 stl_08 stl_09">中查看本政策</span><span
            class="stl_10 stl_08 stl_09"
          >。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_07 stl_08 stl_09">八、如何联系我们
            &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">1</span><span
            class="stl_10 stl_08 stl_09"
          >、如您对本隐私政策或您个人信息的相关事宜有任何问题、意见或建议，请联系兽医首选</span><span
            class="stl_11 stl_08 stl_09"
          >™</span><span class="stl_10 stl_08 stl_09">客户服 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >务部进行初步咨询，客户服务部电话：</span><span
            class="stl_11 stl_08 stl_09"
          >025-86210186</span><span
            class="stl_10 stl_08 stl_09"
          >。如您需要获取具体个人信息副本，您可以通过发 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">送邮件至我们专门的个人信息保护邮箱
          </span><a href="mailto:office@petcare.cn" target="_blank"><span class="stl_11 stl_08 stl_09"
                                                                          style="word-spacing:-0.0454em;"
          >office@petcare.cn </span></a><span
            class="stl_10 stl_08 stl_09"
          >的方式与我们联系。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">2</span><span
            class="stl_10 stl_08 stl_09"
          >、兽医首选</span><span class="stl_11 stl_08 stl_09">™</span><span
            class="stl_10 stl_08 stl_09"
          >平台运营者为南京瀚星信息科技有限公司，注册地址为南京市鼓楼区广州路 </span><span
            class="stl_11 stl_08 stl_13"
          >213 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_10 stl_08 stl_09">号，常用办公地址为江苏省南京市建邺区创智路
          </span><span class="stl_11 stl_08 stl_09" style="word-spacing:-0.0459em;">1 </span><span
            class="stl_10 stl_08 stl_09"
          >号北纬国际中心 </span><span class="stl_11 stl_08 stl_09"
                                style="word-spacing:-0.0458em;"
          >A </span><span class="stl_10 stl_08 stl_09">栋 </span><span
            class="stl_11 stl_08 stl_14" style="word-spacing:-0.0446em;"
          >10 </span><span
            class="stl_10 stl_08 stl_09"
          >层。 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span class="stl_11 stl_08 stl_09">3</span><span
            class="stl_10 stl_08 stl_09"
          >、一般情况下，我们将在十五天内回复。如果您对我们的回复不满意，特别是我们的个人信息处 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_10 stl_08 stl_09"
          >理行为损害了您的合法权益，您还可以向网信、电信、公安及工商等监管部门进行投诉或举报，或</span><span
            class="stl_07 stl_08 stl_09"
          >通过向 &nbsp;</span></div>
          <div class="stl_01" style="left:0.5em;;"><span
            class="stl_07 stl_08 stl_09"
          >被告住所地有管辖权的法院提起诉讼来寻求解决方案。 &nbsp;</span></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import './index.scss'
export default {
  data() {
    return {

    }
  },
  created() {

  },
  methods: {

  }
}
</script>

<style>
sup {
  vertical-align: baseline;
  position: relative;
  top: -0.4em;
}
sub {
  vertical-align: baseline;
  position: relative;
  top: 0.4em;
}
a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
@media screen and (min-device-pixel-ratio: 0),
  (-webkit-min-device-pixel-ratio: 0),
  (min--moz-device-pixel-ratio: 0) {
  .stl_view {
    font-size: 10em;
    transform: scale(0.1);
    -moz-transform: scale(0.1);
    -webkit-transform: scale(0.1);
    -moz-transform-origin: top left;
    -webkit-transform-origin: top left;
  }
}
.layer {
}
.ie {
  font-size: 1pt;
}
.ie body {
  font-size: 12em;
}
@media print {
  .stl_view {
    font-size: 1em;
    transform: scale(1);
  }
}
.grlink {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1000000;
}
.stl_01 {
  width: 9.47em;
}
.stl_02 {
  font-size: 1em;
  line-height: 0em;
  width: 100%;
  height: 70.08334em;
  border-style: none;
  display: block;
  margin: 0em;
}

@supports (-ms-ime-align: auto) {
  .stl_02 {
    overflow: hidden;
  }
}
.stl_03 {
  position: relative;
}
.stl_04 {
  /* position: absolute;
  left: 0em;
  top: 0em; */
}
.stl_05 {
  position: relative;
  width: 100%;
}
.stl_06 {
  height: 7.008333em;
}
.ie .stl_06 {
  height: 70.08334em;
}

.stl_07 {
  font-size: 0.75em;
  font-weight:bold;
  color: #333333;
}
.stl_08 {
  line-height: 1.319824em;
}
.stl_09 {
  letter-spacing: 0em;
}

.ie .stl_09 {
  letter-spacing: 0px;
}
.stl_10 {
  font-size: 0.75em;
  font-weight:bold;
  color: #333333;
}
.stl_11 {
  font-size: 0.75em;
  font-weight:bold;
  color: #333333;
}
.stl_12 {
  letter-spacing: -0.0001em;
}

.ie .stl_12 {
  letter-spacing: -0.0011px;
}
.stl_13 {
  letter-spacing: 0.0001em;
}

.ie .stl_13 {
  letter-spacing: 0.0008px;
}
.stl_14 {
  letter-spacing: -0.0004em;
}

.ie .stl_14 {
  letter-spacing: -0.0051px;
}
.stl_15 {
  letter-spacing: -0.23em;
}

.ie .stl_15 {
  letter-spacing: -2.76px;
}
.stl_16 {
  letter-spacing: 0.0067em;
}

.ie .stl_16 {
  letter-spacing: 0.08px;
}
.stl_17 {
  letter-spacing: 0.0006em;
}

.ie .stl_17 {
  letter-spacing: 0.0067px;
}

.stl_18 {
  font-size: 0.75em;
  font-weight:bold;
  color: #333333;
}
.stl_19 {
  letter-spacing: 0.0002em;
}

.ie .stl_19 {
  letter-spacing: 0.002px;
}
.stl_20 {
  letter-spacing: -0.0002em;
}

.ie .stl_20 {
  letter-spacing: -0.0022px;
}
</style>
